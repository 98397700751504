<template>
    <div class="add-new-actype">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.acTypeUpdate')"
            :title="$t('messages.acTypeUpdate')"
            @submitPressed="submitPressed"
        />
        <resource-form v-if="acType" :resource-object="acType" :resource="$AcTypes" base-url="/settings/ac-types"
                       create-message="messages.acTypeCreated" update-message="messages.acTypeUpdated" :action="action"
                       :emails="false" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceForm from '../../Settings/components/ResourceForm.vue'

export default {
    components: {
        TitleBar,
        ResourceForm
    },
    data() {
        return {
            acType: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$AcTypes.getResource({id}).then((response) => {
                this.acType = response.data;
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
